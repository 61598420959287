import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppFontSize } from '@pixels/client/pixels/pixels.model';
import { AppKeySoulmate } from '@pixels/universal/model/apps/app-types';
import { SoulmateProjectMeta } from '@pixels/universal/model/apps/project-meta/soulmate-project-meta';

@Injectable({ providedIn: 'root' })
export class SoulmateAppInfo extends AppInfo {
  public readonly appType = AppKeySoulmate;
  public readonly serviceName = _('@소울메이트@');
  public readonly appStoreUrl = signal(SoulmateProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${SoulmateProjectMeta.appId}`);

  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? SoulmateProjectMeta.ios?.admobBannerVideoChatAppId : SoulmateProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly loginTitle = _('@소울메이트@');
  public override readonly loginDescription = _('@편하게_대화나눌_n_또래친구가_필요하다면@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/soulmate-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@소울메이트_팬채팅_나와_닮은_아이돌_찾기@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
  public override readonly fontSize: AppFontSize = AppFontSize.size2;
  public override readonly agreementAge = 18;
}
