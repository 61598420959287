import { AppKeySoulmate } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'soulmate-client';
const PROD_WEB_PUSH_KEY = 'BDaDyZldVA-kpa1kvbMGGYR3cjTyYHgoBnK3vDyHfEBDjbAhGaZ4qzRKrchSSDFAoGgmFEBZ1ozJbANiWYl-dH4';
const DEV_WEB_PUSH_KEY = 'BITw4fTiJ3xmcON9chOywGG-sCvpAw7OiBG6u9y5SA6U5XwqQku9lQsi90lGe6YuSZixDpNLp265yX-kUMpCMY4';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyAxXhu7LSfFjZ8X-q0ZAbBenLFRyJaKH9g',
  authDomain: 'pixels-soulmate.firebaseapp.com',
  projectId: 'pixels-soulmate',
  storageBucket: 'pixels-soulmate.appspot.com',
  messagingSenderId: '203118050747',
  appId: '1:203118050747:web:c74b10777e2e4bb966989e',
  measurementId: 'G-0KXPW7RMF1'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyBfJfN4ozHHXvoFJdBWut6pEbMqk_dVRMM',
  authDomain: 'pixels-soulmate-dev.firebaseapp.com',
  projectId: 'pixels-soulmate-dev',
  storageBucket: 'pixels-soulmate-dev.appspot.com',
  messagingSenderId: '877267353550',
  appId: '1:877267353550:web:ace9bd229c110675af359f',
  measurementId: 'G-WEY9D6NQGH'
};
const PROD_TALK_PLUS_APP_ID = '90c1c3ce-a585-4968-9035-1cb203bcf6d1';
const DEV_TALK_PLUS_APP_ID = '15ec1d9d-8af7-4b16-b6f5-51d43eb3435a';

export const SoulmateProjectMeta: ProjectMeta = {
  id: AppKeySoulmate,
  mainPrimaryColor: '#F73859',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8108,
  apiName: 'soulmate-api',
  apiPort: 5008,
  appId: 'io.pixelteam.soulmate',
  appStoreUrl: 'https://apps.apple.com/app/id6473159302',
  appName: '소울메이트',
  appNameEn: 'Soulmate',
  appSchemeName: 'Soulmate',
  testTargetUrl: 'https://test-soulmate.pixelteam.io',
  devTargetUrl: 'https://dev-soulmate.pixelteam.io',
  prodApi: 'https://soulmate-api.pixelteam.io',
  devApi: 'https://dev-soulmate-api.pixelteam.io',
  firebaseId: 'pixels-soulmate',
  testHostname: 'test-soulmate',
  clientSentryDsn: 'https://fa48e8ad9fd1f13104c47e8c4547d070@o190729.ingest.sentry.io/4506279470694400',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11301/deploy',
    targets: ['prod-01'],
    port: 9997,
    androidAppId: '1:203118050747:android:51277bf01eba283d66989e',
    iosAppId: '1:203118050747:ios:a4aff8a29595c7b466989e',
    iosEncodingAppId: 'app-1-203118050747-ios-a4aff8a29595c7b466989e',
    webAppId: '1:203118050747:web:c74b10777e2e4bb966989e',
    kakaoAppKey: 'ffaf4290ef5046822507cfe38fa7b5fd',
    googleReversedId: 'com.googleusercontent.apps.203118050747-b3sh2c20ek0u2vr3a43a557d99rl9h30',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://soulmate-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11288/deploy',
    targets: ['dev-01'],
    port: 9998,
    androidAppId: '1:877267353550:android:5bc7c3fc1f779fadaf359f',
    iosAppId: '1:877267353550:ios:8dad102b2b3a27a3af359f',
    iosEncodingAppId: 'app-1-877267353550-ios-8dad102b2b3a27a3af359f',
    webAppId: '1:877267353550:web:ace9bd229c110675af359f',
    kakaoAppKey: '33cb304a38aefe07cdfdbcb5e82b9556',
    googleReversedId: 'com.googleusercontent.apps.877267353550-90kgd83f7op9r63teq2j69tujgvst1cj',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-soulmate-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:877267353550:android:4f9819b144b7106eaf359f',
    iosAppId: '1:877267353550:ios:6e19bf0644e00a6daf359f',
    iosEncodingAppId: 'app-1-877267353550-ios-6e19bf0644e00a6daf359f',
    kakaoAppKey: '33cb304a38aefe07cdfdbcb5e82b9556',
    googleReversedId: 'com.googleusercontent.apps.877267353550-pdsidimirredfd75sgpfbibkvvcombm4',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-soulmate-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~4298012976',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/4779360710',
  },
  ios: {
    admobAppId: 'ca-app-pub-4000507444081320~5013781543',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/7213952361',
  },
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 소울메이트에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach a photo taken with your camera to a message or list it as your profile picture.',
  NSPhotoLibraryAddUsageDescriptionEn: 'Enable access to save photos to your soulmate.',
  NSPhotoLibraryUsageDescriptionEn: 'You can use photos and GIFs from your albums as attachments to messages or as your profile picture.',
  consoleLogo: `
███████╗ ██████╗ ██╗   ██╗██╗     ███╗   ███╗ █████╗ ████████╗███████╗
██╔════╝██╔═══██╗██║   ██║██║     ████╗ ████║██╔══██╗╚══██╔══╝██╔════╝
███████╗██║   ██║██║   ██║██║     ██╔████╔██║███████║   ██║   █████╗
╚════██║██║   ██║██║   ██║██║     ██║╚██╔╝██║██╔══██║   ██║   ██╔══╝
███████║╚██████╔╝╚██████╔╝███████╗██║ ╚═╝ ██║██║  ██║   ██║   ███████╗   
`
};
